@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Poppins:wght@400;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  .text-color {
    color: rgba(97, 97, 97, 1);
  }
}

body {
  overflow-x: hidden;
  margin: 0;
}

.w-container {
  width: 1300px;
  margin: auto;
  @media (max-width: 1360px) {
    width: 960px;
  }
  @media (max-width: 1170px) {
    width: 760px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 24px;
  }
}

.app {
  position: relative;
  overflow-x: hidden;
  .header-content {
    display: flex;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 150px 0 30px 0;

    .text-area {
      margin-right: 100px;

      .title {
        font-size: 60px;
        font-weight: 700;
        margin-bottom: 55px;
      }
    }

    .img-area {
      img {
        width: 665px;
      }
    }

    .info-box-area {
      margin-top: 130px;

      .info-box-top {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 100px;
      }

      .info-box-bottom {
        display: flex;
        justify-content: space-evenly;
        padding: 0 150px;
      }
    }
  }

  section {
    &#topInfo {
      background-color: rgba(130, 0, 244, 0.07);
    }
  }

  .gray-area {
    background: #f4f4f4;
    padding: 85px 0 0 0;
    width: 100%;
    margin: auto;
  }

  .home-abs-img {
    width: 885px;
    height: 1650px;
    &.home-abs-left-img {
      background-image: url("/img/left_hex.png");
      left: 0;
    }
    &.home-abs-right-img {
      background-image: url("/img/right_hex.png");
      right: 0;
    }

    @media (max-width: 1170px) {
      &.home-abs-left-img {
        left: -100px;
      }
      &.home-abs-right-img {
        right: -100px;
      }
    }
  }

  .form {
    padding: 95px 0;
    width: 100%;
    margin-top: 280px;
    margin-bottom: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .info-box-area {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 1360px) {
    .header-content {
      padding: 100px 0 30px 0;
      .text-area {
        margin-right: 75px;
        .title {
          font-size: 44px;
        }
      }
      .img-area {
        img {
          width: 500px;
        }
      }
    }
  }
  @media (max-width: 1170px) {
    .header-content {
      .text-area {
        margin-right: 36px;
        .title {
          font-size: 36px;
        }
      }
      .img-area {
        img {
          width: 400px;
        }
      }
    }
    .form {
      padding: 95px 30px;
    }
  }
  // @media (max-width: 760px) {
  //   .form {
  //     padding: 95px 30px;
  //   }
  // }
  @media (max-width: 800px) {
    .header-content {
      flex-direction: column;
      .text-area {
        order: 2;
        margin-top: 36px;
        margin-right: 0;
        text-align: center;
        .title {
          font-size: 36px;
        }
      }
      .img-area {
        order: 1;
      }
    }
  }
  @media (max-width: 460px) {
    .header-content {
      .img-area {
        img {
          width: 360px;
        }
      }
    }
  }
}
