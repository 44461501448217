.button{
    cursor: pointer;
    padding:18px 36px;
    color: white;
    border-style: none;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;

    &.small{
        padding: 12px 24px;
    }
}