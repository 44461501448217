.arrow {
  width: 600px;
  margin: auto;

  @media (max-width: 660px) {
    width: 100%;
    padding: 0 30px;
  }
}
.form-text {
  display: flex;
  justify-content: center;
  color: white;
  margin: 40px 0 65px 0;
  text-align: center;
}
.form-area {
  width: 820px;
  display: block;
  margin: auto;
  .form-side {
    // display: flex;
    // margin: auto;
    // .left,
    // .right {
    //   width: 100%;
    // }
    // .left {
    //   margin-right: 50px;
    // }
  }
  .inputs-area {
    display: flex;
    align-items: center;
    .input-box {
      width: 100%;
      padding: 0 24px;
      .field {
        padding: 12px 15px;
        border: 1px solid #fff;
        background-color: rgba(249, 249, 249, 0);
        width: 100%;
        color: white;
      }
      label {
        display: block;
        color: white;
        margin: 25px 0 15px 0;
      }
    }
  }
  @media (max-width: 900px) {
    width: 700px;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    .form-side{
      .inputs-area{
        display: block;
        .input-box{
          padding: 0;
        }
      }
    }
  }
}
.submit-btn-area {
  display: flex;
  justify-content: center;
  margin-top: 55px;
  button {
    cursor: pointer;
    background-color: rgba(130, 0, 244, 1);
    padding: 17px 30px;
    color: white;
    border: unset;
  }
}
