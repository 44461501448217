.vector{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    p{
        margin: 24px 0;
        text-align: center;
    }
}