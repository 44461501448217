.blog {
  .blog-title {
    color: #15364c;
    font-size: 36px;
    font-weight: 700;
    padding: 200px 0 55px 0;
  }
  .blog-text {
    color: #15364c;
    margin-bottom: 55px;
  }
  .grid {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 160px;
    gap: 50px 50px;
    .card {
      border-radius: 20px;
      box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
      padding: 20px;
      background-color: rgba(255, 255, 255, 1);
      text-decoration: unset;
      &.secondary {
        background-color: rgba(21, 54, 76, 1);

        .text,
        .title {
          color: white !important;
        }
      }
      .img-area {
        position: relative;
        width: 100%;
        height: 220px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .detail-img {
          position: absolute;
          transform: translate(-50%, -50%) scale(0);
          top: 50%;
          left: 50%;
          transition: 0.4s;
        }
      }

      .text-area {
        .title {
          font-size: 24px;
          font-weight: 700;
          margin-top: 20px;
        }
        .text {
          color: #15364c;
          padding-top: 10px;
        }
      }

      &:hover {
        .img-area {
          .detail-img {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }
  }
}

@media (max-width: 1360px) {
  .blog {
    .grid {
      grid-template-columns: auto auto;
    }
  }
}

@media (max-width: 1170px) {
  .blog {
    .grid {
      grid-gap: 24px;
    }
  }
}
@media (max-width: 800px) {
  .blog {
    .grid {
      grid-template-columns: auto;
    }
  }
}
