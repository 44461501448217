.nav {
  border-bottom: 1px solid rgba(130, 0, 244, 0.2);
  .nav-link {
    color: rgba(130, 0, 244, 0.5);
    padding: 12px 20px;
    font-size: 14px;
    &.active {
      color: rgba(130, 0, 244, 1);
    }
  }
}

.nav-pills {
    margin-bottom: 24px;
    border-bottom: unset;
    .nav-link {
      // width: 220px;
      background-color: rgba(130, 0, 244, 0.1);
      color: rgba(130, 0, 244, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      &.active {
        background-color: rgba(130, 0, 244, 1);
        color: white;
      }
    }
    .nav-item {
      display: flex;
    }
  }