.footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  margin: auto;
  .left-side {
    .logo {
      margin-bottom: 50px;
    }
    .phone,
    .email {
      display: flex;
      .phone-icon,
      .email-icon {
        margin-right: 15px;
      }
    }
    .phone {
      margin-bottom: 15px;
    }
  }
  .right-side {
    .categories {
      display: flex;
      div{
        &.companies-btns{
          margin-right: 200px;
        }
        .footer-title{
          font-weight: 600;
          margin-bottom: 10px;
        }
        a{
          margin: 10px 0;
          display: block;
          text-decoration: unset;
          color: rgb(21, 54, 76);
        }
      }
    }
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 40px 0;
  margin: auto;
  border-top: 1px solid #757575;
  .copy {
    display: flex;
    .copy-icon {
      margin-right: 20px;
    }
  }
  .socials {
    display: flex;
    width: 180px;
    height: 24px;
    justify-content: space-between;
  }
}

@media (max-width: 1170px) {
  .footer {
    .right-side {
      // width: 400px;
      .categories{
        div{
          &.companies-btns{
            margin-right: 100px;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .footer {
    flex-direction: column;
    .left-side {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .right-side {
      width: 100%;
      margin-bottom: 50px;
      margin-top: 50px;
    }
  }
  .bottom {
    flex-direction: column;
    align-items: center;
    .socials {
      margin-top: 24px;
    }
  }
}

@media (max-width: 500px) {
  .footer{
    .right-side{
      .categories{
        flex-direction: column;
        div{
          &.services-btns{
            margin-top: 50px;
          }
        }
      }
    }
  }
}