.accordion{
    .accordion-item{
        .accordion-header{
            .accordion-button{
                padding: 12px;
                background-color: rgba(130, 0, 244, 1);
                color: white;
                &.collapsed{
                    background-color: rgba(130, 0, 244, 0.1);
                    color: rgba(130, 0, 244, 1);
                }
            }
        }
        .accordion-body{
            padding: 12px;
            label{
                text-align: left;
            }
        }
    }
}