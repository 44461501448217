nav {
  .w-container {
    position: relative;
  }
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 25px 15px;
    margin: auto;

    .nav-right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu {
        display: flex;
        justify-content: space-evenly;

        a,
        .span {
          font-family: "Poppins" sans-serif;
          cursor: pointer;
          padding: 16px 0;
          margin: 0 12px;
          color: rgba(0, 0, 0, 1);
          text-decoration: none;
        }

        .services {
          margin: auto;
        }

        .span {
          position: relative;
        }

        .hover {
          position: absolute;
          display: block;
          border-radius: 12px;
          background: white;
          box-shadow: 0px 0px 8px 0px rgba(159, 159, 159, 0.25);
          padding: 12px 0 12px 0;
          margin-top: 15px;

          a {
            display: block;
            padding: 12px 18px;
            margin: unset;

            &:hover {
              background-color: rgba(21, 54, 76, 1);
              color: white;
            }
          }
        }
      }
      .mobil-nav-btn {
        padding: 12px 16px;
        background-color: #15364c0f;
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  #mobil-nav {
    display: none;
    .mobil-nav {
      width: 80%;
      height: 100vh;
      background-color: white;
      padding-top: 100px;
      position: fixed;
      top: 0;
      left: -80%;
      transition: 0.3s;
      z-index: 2;
      &.open {
        left: 0;
      }
      a {
        text-decoration: none;
        color: black;
        &.button {
          color: white;
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        &.services-ul-mobil {
          padding-top: 12px;
        }
        li {
          .mobil-nav-menu-btn {
            padding: 16px 24px;
            display: block;
            margin: 10px 0;
            &:hover {
              background-color: #15364c0f;
            }
          }
          .mobil-nav-menu-btn-li-btn {
            padding: 16px 36px 16px 24px;
            display: block;
            margin: 0;
            &:hover {
              background-color: #15364c0f;
            }
          }
          a {
            display: block;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  nav {
    .navbar {
      .nav-right {
        .menu {
          display: none;
        }
        .button {
          display: none;
        }
        .mobil-nav-btn {
          display: flex;
        }
      }
    }
    #mobil-nav {
      display: block;
    }
  }
}

@media (max-width: 400px) {
  nav {
    #mobil-nav {
      .mobil-nav {
        width: 100%;
        left: -100%;
      }
    }
  }
}
