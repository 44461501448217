.top-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 150px 0 70px 0;
  position: relative;
  .text-area {
    position: relative;
    z-index: 1;
    .title {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .sub-title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 55px;
      text-align: center;
    }

    .text {
      font-size: 16px;
      font-weight: 500;
      color: #757575;
      margin-bottom: 45px;
    }
  }
  .absolute-img {
    position: absolute;
    right: 0;
    bottom: -50px;
    z-index: 0;
  }
}

@media (max-width: 1360px) {
  .top-info {
    .text-area {
      .title {
        font-size: 44px;
      }
      .sub-title {
        font-size: 36px;
      }
      .text {
        font-size: 20px;
      }
    }
  }
}
