.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc((100% / 3) - 50px);
  padding: 25px;
  .text-area {
    text-align: center;
    text-align: justify;
    .title {
      padding: 45px 0 35px 0;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }
  }
}

@media (max-width: 1360px) {
  .info-box {
    width: calc(100% / 2);
  }
}

@media (max-width: 800px) {
  .info-box {
    width: calc(100%);
  }
}
