.corparation{
  padding-bottom: 50px;
}

.corp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 95px 15px 120px 15px;
  margin: auto;
  display: flex;
  .icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    &.width{
        img{
            width: 250px;
        }
    }
    &.height{
        img{
            height: 50px;
        }
    }
    img{
        object-fit: contain;
    }
  }
}
.arrow-btn {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 115px;
  svg {
    cursor: pointer;
  }
}

@media (max-width: 1360px) {
  .corp {
    padding: 95px 0 120px 0;
    .icons {
    &.width{
        img{
            width: 200px;
        }
    }
    &.heigt{
        img{
            height: 50px;
        }
    }
    }
  }
}
