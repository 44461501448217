.w-container {
  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    margin: 65px 0;
    &.bg-content {
      background-color: rgba(21, 54, 76, 1);
      border-radius: 27px;
      padding: 60px 50px;
      .text,
      .title {
        color: white;
      }
    }
    .text-area {
      &.right {
        margin-right: 260px;
      }

      &.left {
        margin-left: 260px;
      }
      .title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      .text {
        margin-bottom: 36px;
      }
    }
    .img-area {
      img {
        width: 470px;
      }
    }
  }
  @media (max-width: 1360px) {
    .content {
      &.bg-content {
        padding: 30px;
      }

      .text-area {
        &.right {
          margin-right: 120px;
        }

        &.left {
          margin-left: 120px;
        }

        .title {
          font-size: 30px;
        }
      }
      .img-area {
        img {
          width: 340px;
        }
      }
    }
  }

  @media (max-width: 1170px) {
    .content {
      &.bg-content {
        padding: 30px;
      }

      .text-area {
        &.right {
          margin-right: 50px;
        }

        &.left {
          margin-left: 50px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .content {
      flex-direction: column !important;

      .text-area {
        order: 2;
        margin-right: 0;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        &.right {
          margin-right: 0;
        }
        &.left {
          margin-left: 0;
        }
      }
      .img-area {
        order: 1;
      }
    }
  }

  @media (max-width: 460px) {
    .content{
      .img-area{
        img{
          width: 300px;
        }
      }
    }
  }
}
